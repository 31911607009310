import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'


// Styles
import { AccordionHolder } from "./Accordion.styles"

const AccordionComponent = () => {

    return (
        <AccordionHolder>
            <Container>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            HUR MYCKET KOSTAR DET ATT FLYTTA?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                        Vi har börjat erbjuda fasta priser i Göteborg, Borås, Växjö och Jönköping.
                             Bostäder belägna på markplan exempelvis villa,radhus etc 
                             med parkering max 10 meter från dörren kostar 60kr/kvadraten.
                              Lägenhet med trappa, hiss eller långt att gå kostar 90kr/kvadraten. 
                              Minsta debitering 50kvadrat. Priserna är inklusive moms och efter rutavdrag.
                            Fast pris gäller endast om ert bohag är packat och klart när vår personal kommer.
                            Se Material & Packtips för tips.

                            Vi erbjuder självklart fastpris på hela flyttuppdrag med packning etc. 
                            Kontakta oss för prisuppgift.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        HUR LÅNG TID TAR DET ATT FLYTTA?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                        Att flytta inom Göteborg, Borås med omnejd går i regel på en dag. 
                        Vi erbjuder även packning, demontering samt avyttring i samband med flytt. 
                        Självklart erbjuder vi endast enstaka tjänster som exempelvis avyttring av dödsbo.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        ÄR VI FÖRSÄKRADE?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body>
                        Vi är helt ansvarsförsäkrade och denna försäkring har vi genom If Försäkringar. Läs gärna mer på deras hemsida.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        FLYTTAR VI BARA INOM VÄSTRA GÖTALAND?                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body>
                        Vi flyttar oftast inom Västra Sverige. Men kör regelbundet till både Göteborg 
                        och Växjö samt övriga städer. Vi erbjuder fast pris till Stockholm med lätt 
                        lastbil upp till 22 kubik för 12500kr ink moms efter rutavdrag. 
                        Göteborg med lätt lastbil upp till 22 kubik för 8500kr ink moms efter rutavdrag.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        ÄR NI ETT FLYTTFÖRETAG SOM JAG KAN LITA PÅ?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body>Ett bra tips är att kolla omdöme på internet samt kolla i 
                            ert kontaktnät. Ni kan läsa lite om oss Reco.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        FLYTTAR VI PIANO OCH FLYGLAR?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                        <Card.Body>Vi flyttar självklart dessa otympliga instrument och prydnadsmöbler. 
                            Vi har rätt utrustning och kompetens för denna typ av flytt. Tänk på att om det 
                            inte är i samband med flytt så har man inte rätt att nyttja rutavdraget vid flytt av enstaka möbel.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        ÄR DÄR NÅGRA SAKER SOM VI INTE FÅR LOV ATT TRANSPORTERA?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                        <Card.Body>
                        Transport av föremål som kan vålla skada på person eller egendom 
                        såsom brandfarliga, explosiva, frätande eller illaluktande ämnen, 
                        vapen och ammunition mm. För mer information se:
                        Allmänna Bestämmelser för Bohagsflytt
                        Allmänna Bestämmelser för Kontorsflytt
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        NÄR I MÅNADEN ÄR DET BÄST ATT FLYTTA?                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                        <Card.Body>Det är oftast lugnast mitt i månaden.</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="8">
                        VAD ÄR TRAFIKTILLSTÅND?                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                        <Card.Body>
                        Enligt yrkestrafiklagen får yrkesmässig trafik endast bedrivas av den som har trafiktillstånd.
Detta kan du kontrollera på transportstyrelsen.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="9">
                        HUR OCH NÄR SKA JAG BETALA FÖR FLYTTEN?                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                        <Card.Body>Efter utförd flytt med kort, swish eller via faktura.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="10">
                        OM JAG INTE FÅR PLATS MED MINA SAKER, KAN JAG FÖRVARA DEM HOS ER?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                        <Card.Body>
                        Vi har magasin boxar på 10 kubik där ni kan förvara ert arvegods 
                        eller saker som ni inte vill göra er av med. Kort eller lång tid väljer ni själv.
                        Det kostar 600kr per box och månad. Tänk på att det är eran hemförsäkring som täcker ert gods.
                        Vi har larm via Securitas direkt med väktarutryckning samt kameraövervakning.                        
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="11">
                        KAN JAG PACKA SJÄLV?                      </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="11">
                        <Card.Body>Ja, Men ni är dock själva ansvarig för saker som ni har packat själv. 
                            Allmänna Bestämmelser för Bohagsflytt</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="12">
                        HUR PACKAR JAG EN TV?                       </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="12">
                        <Card.Body>Det bästa är om du har kvar kartongen. Har ni inte det så säljer 
                            vi lämpligt emballage. Vi har så klart flytt filtar som den kan svepas in i under flytt.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="13">
                        KAN JAG LÅTA MINA KLÄDER OCH SAKER LIGGA KVAR I SKÅP OCH BYRÅ LÅDOR?                       </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="13">
                        <Card.Body>Det bästa är om ni tömmer samtliga skåp och lådor, de flesta möbler 
                            idag klarar inte av att transporteras med innehåll.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="14">
                        HUR GÖR VI MED SÄNGAR OCH ANDRA TEXTILER SOM ÄR KÄNSLIGA MOT SMUTS?                      </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="14">
                        <Card.Body>Har ni inte möjlighet att fixa det innan så hjälper vi er gärna.
                            Vi har krympplast som vi kan emballera era sängar med. Bäddmadrasser och liknande läggs i sopsäckar.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="15">
                        HUR GÖR JAG MED MINA VÄXTER OCH BLOMMOR?                       </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="15">
                        <Card.Body>Mindre växter och blommor packas ned i flyttkartonger med locken öppna. 
                            Större växter flyttas som de står i krukor. Vi gör vårt yttersta och hanterar 
                            dina växter med största omsorg, men tyvärr så kan vi inte garantera att de klarar 
                            kyla och värme växlingar vilket innebär att vi inte kan ansvara för dem.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="16">
                        HUR GÖR JAG MED MINA VÄXTER OCH BLOMMOR?                       </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="16">
                        <Card.Body>Mindre växter och blommor packas ned i flyttkartonger med locken öppna.
                             Större växter flyttas som de står i krukor. Vi gör vårt yttersta och hanterar dina 
                             växter med största omsorg, men tyvärr så kan vi inte garantera att de klarar kyla 
                             och värme växlingar vilket innebär att vi inte kan ansvara för dem.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="17">
                        HUR GÖR JAG MED SMYCKEN, JUVELER OCH ANDRA VÄRDESAKER?                     </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="17">
                        <Card.Body>Om möjligt transportera detta själva. Om inte möjlighet finns så 
                            meddela arbetsledaren om att ni har extra dyrbart gods.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="18">
                        KAN JAG TA MED MIG KYL & FRYS VAROR?                    </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="18">
                        <Card.Body>Ja, Vi gör vårt yttersta för att maten ska klara sig. Packa om möjligt i kylväskor.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="19">
                        HUR GÖR JAG MED HUSDJUR?                      </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="19">
                        <Card.Body>Dessa flyttar ni om möjligt innan eller efter flyttlasset. Vi tar självklart 
                            fågelburar, akvarie etc vid behov. Djuren i sig har ni i transportbox under tiden.</Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </Container>
        </AccordionHolder>
    )
}

export default AccordionComponent 