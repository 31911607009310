import styled from "styled-components"

export const PaketPrisHolder = styled.div`
width: 450px;
height: 255px;
background-image: radial-gradient(ellipse farthest-corner at 50% 50%,#fff 25%,#eee);
background-repeat: no-repeat;
display: flex;
position: relative;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
margin-bottom: 35px;

.paket-left {
    width: 177px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;

    .fran {
        margin: 0;
        font-size: .7rem;
        color: #555;
    }
    h4 {
        margin:0;
        font-size: 1.3rem;
        padding-bottom:10px;

    }
    .inc {
        margin: 0;
        margin-bottom:35px;
        font-size: .7rem;
        color: #555;
    
    }
    a {
        margin: 0;
        background:${props => props.theme.colors.prime};
        color:white;
        border-radius: 5px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-size: .8rem;
        text-decoration: none;
        padding: 5px 10px;

        &:hover {
            background: transparent;
            color: ${props => props.theme.colors.prime};
        }


    }

}
.paket-right {
    width: 273px;
    height: 100%;
    border-color: red;
    border-left:dashed 1px ${props => props.theme.colors.prime};

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    h3 {
        margin-top: 0;
        width: 100%;
        padding: 15px;
        padding-bottom: 0;
        color: #555;
        font-size:1rem;
    }
    p {
        font-size: .9rem;
        padding: 15px;
        padding-bottom:0;
        margin: 0;
        color: #555;
    }
    .utoka {
        color: ${props => props.theme.colors.prime} !important;
        font-size: .8rem;
    }
}
`