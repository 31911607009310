import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PaketPris from 'components/PaketPris'
import { BsPersonFill, BsPeopleFill } from "react-icons/bs";
import Image from "gatsby-image" 


import { TwoColsHolder } from "./TwoCols.styles" 

const TwoCols = (props) => ( 
    <TwoColsHolder className={props.Class}> 
        <Container>
            <Row>
                <Col>
                {props.Header === true &&
                    <h3 className="rubrik-main">{props.Header}</h3>
                }
                    <p>{props.Content}</p>
                </Col>
            </Row>
            <Row>
                <Col className="left-col">
                {props.HeaderLeft && 
                    <>
                        {props.IconLeft} 
                        <h3>{props.HeaderLeft}</h3>
                    </>
                    }
                
                    <div className={props.ConentClass}>
                        <img src={props.ImgLeft} />
                        <h3>{props.ContentLeftHeader}</h3>
                        <p>{props.ContentLeft}</p>
                    </div>
                    
                </Col>
                <Col className="right-col">
                    {props.HeaderRight && 
                    <>
                        {props.IconRight}
                        <h3>{props.HeaderRight}</h3>
                    </>
                    }

                    <div className={props.ConentClass}>
                        <img src={props.ImgRight}/>
                        <h3>{props.ContentRightHeader}</h3>
                        <p>{props.ContentRight}</p>
                        <Image className="template-content-img" style={{width: '150px', margin: '0 auto'}} fluid={props.ImageRight} alt="swish"/>
                    </div>
                </Col>
            </Row>
        </Container> 
    </TwoColsHolder>
)

export default TwoCols