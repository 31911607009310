import styled from "styled-components"

export const TwoColsHolder = styled.div`
margin-top: 100px;
margin-bottom: 10px;
.container {
    padding:0;
}

.rubrik-main {
    text-align:center;
    margin: 0 auto 25px;
    font-size: 2rem;
    color: #555;
    position:relative;
    padding-bottom: 5px;

    &:after {
        content: "";
        background: ${props => props.theme.colors.prime};
        height: 2px;
        width: 50%;
        left: 25%;
        bottom: 0;
        position: absolute;

    }
}

p {
    text-align:center;
    margin: auto;
    color: #555;

}

.left-col {
    svg {
        display: inline-block;
        color:${props => props.theme.colors.prime};
        font-size: 1.5rem;
        margin-right: 10px;
    }
    > h3 {
        margin: 35px 0 15px;
        font-size: 1.3rem;
        color: #555;
        display: inline-block;
        position: relative;
        width: 60%;

        @media screen and (min-width: 767px)  {
            width: 90%;
        }

        &:after {
            content: "";
            height: 1px;
            background: ${props => props.theme.colors.prime};
            width: 50%;
            left:-30px;
            position: absolute;
            bottom: 0;
        }

    }
}

.right-col {
    svg {
        display: inline-block;
        color:${props => props.theme.colors.prime};
        font-size: 1.5rem;
        margin-right: 10px;
    }
    > h3 {
        margin: 35px 0 15px;
        font-size: 1.3rem;
        color: #555;
        display: inline-block;
        position: relative;
        width: 60%;

        @media screen and (min-width: 767px)  {
            width: 90%;
        }

        &:after {
            content: "";
            height: 1px;
            background: ${props => props.theme.colors.prime};
            width: 50%;
            left:-30px;
            position: absolute;
            bottom: 0;
        }
    }
}

&.trygghet-cols{
    .trygghet-content {
        background: #F4F4F4;
        border-radius: 7px;
        position: relative;
        h3 {
            text-align:center;
            padding-top: 35px;            
        }
        p {
            text-align: left;
            font-size: .8rem;
            color: #777;
            padding: 35px;
        }

        img {
            margin: 0 auto;
            position: relative;
            text-align: center;
            top: -30px;
            display: block;
        }
    }
}
`